@font-face {font-family:'FuturaPT-Book';src:url('/s/webfonts/329831_0_0.eot');src:url('/s/webfonts/329831_0_0.eot?#iefix') format('embedded-opentype'),url('/s/webfonts/329831_0_0.woff2') format('woff2'),url('/s/webfonts/329831_0_0.woff') format('woff'),url('/s/webfonts/329831_0_0.ttf') format('truetype');}@font-face {font-family:'FFSuperGroteskWebProMedium';src:url('/s/webfonts/329831_1_0.eot');src:url('/s/webfonts/329831_1_0.eot?#iefix') format('embedded-opentype'),url('/s/webfonts/329831_1_0.woff2') format('woff2'),url('/s/webfonts/329831_1_0.woff') format('woff'),url('/s/webfonts/329831_1_0.ttf') format('truetype');} body{font-family:FuturaPT-Book;margin:0;padding:0;} #header{background-color:#F30024;padding:15px 0;margin:0;text-align:center;} #header img{max-width:50%;} #main{max-width:640px;margin:auto;} h1{color:#F30024;text-transform:uppercase;font-family:FFSuperGroteskWebProMedium;font-size:22px;line-height:1.5;letter-spacing:.3em;text-align:center;font-weight:300;line-height:1.4;margin-top:40px;} .form-group{padding:10px 0;} .form-group label{display:block;padding-left:5px;color:#222;font-size:20px;} .form-group select{width:100%;border-radius:4px;font-size:14px;border:1px solid #ccc;background-color:#fff;height:30px;} .form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="text"]{width:100%;border-radius:4px;line-height:28px;padding-left:5px;font-size:16px;border:1px solid #ccc;background-color:#fff;color:#444;box-sizing:border-box;} .form-group textarea{width:100%;height:80px;border-radius:4px;padding-left:5px;font-size:16px;border:1px solid #ccc;background-color:#fff;color:#444;box-sizing:border-box;} .form-group label[for="note"]{margin-top:20px;margin-bottom:5px;color:#222;font-size:22px;font-weight:bold;} .form-group-holder{padding-top:20px;} .form-group-holder h3{margin-bottom:5px;color:#222;font-size:22px;} .form-group-holder .form-group{padding:5px 0;} .form-group-holder .form-group label{display:inline-block;color:#666;font-size:19px;} input[type="submit"],
button{border:2px solid #F30024;background-color:#fff;color:#F30024;text-transform:uppercase;font-family:FFSuperGroteskWebProMedium;font-size:14px;letter-spacing:.3em;text-align:center;font-weight:300;line-height:3;padding:0 20px;border-radius:0;box-sizing:border-box;-webkit-appearance:none;-webkit-border-radius:0px;} input[type="submit"]:hover,
button:hover{background-color:#F30024;color:#fff;cursor:pointer;} .message,
span.error{padding-left:5px;color:#F30024;display:block;} #login{max-width:300px;margin:30px auto;} #login input[type="submit"]{margin:30px auto;text-align:center;width:100%;cursor:pointer;box-shadow:none;display:block;-webkit-appearance:none;-webkit-border-radius:0px;} #events{width:90%;margin:auto;} #events a{border:1px solid #ccc;padding:10px;width:100%;display:block;margin:20px auto;color:#444;text-decoration:none;box-sizing:border-box;background-image:url('/i/next.svg');background-repeat:no-repeat;background-position:right 10px center;background-size:18px;} #events a:hover{background-color:#444;color:#fff;border:1px solid #222;} #thank,
#survey{position:relative;padding-bottom:40px;} #thank .survey-name,
#survey .survey-name{position:absolute;font-weight:normal;bottom:-130px;right:0;color:#aaa;font-size:14px;} #thank input[type="submit"],
#survey input[type="submit"]{width:100%;margin-top:30px;} #thank{text-align:center;padding:40px 0 100px 0;} #thank h1{color:#444;} #thank a{color:#F30024;text-decoration:none;margin-bottom:30px;font-size:22px;} #thank a:hover{text-decoration:underline;} #thank button{display:block;margin:30px auto;} a.dismiss{text-decoration:none;color:#F30024;background-image:url('/i/goback.svg');background-position:left -1px;background-repeat:no-repeat;background-size:15px;padding-left:15px;} #navigation{border-top:1px solid #ccc;color:#ccc;margin-top:80px;} #navigation button,
#navigation a{text-decoration:none;color:#ccc;font-size:14px;padding:10px 20px;margin:0 10px 0 0;background:#fff;box-sizing:border-box;box-shadow:none;text-transform:none;font-family:FuturaPT-Book;letter-spacing:0;line-height:1.5;border:none;display:inline-block;} #navigation button:hover,
#navigation a:hover{box-sizing:border-box;}@media (max-width:900px){ #main{padding:0 8%;} #thank .survey-name,
#survey .survey-name{bottom:-100px;}}